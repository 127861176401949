import { signOut } from 'aws-amplify/auth';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../../../../constants/Icons';
import { ROUTE } from '../../../../../constants/Routes';
import {
  handleToken,
  handleUserLoggedIn,
} from '../../../../../redux/Reducer/Auth';
import { toggleSnackBar } from '../../../../../redux/Reducer/Utils';
import {
  getCountries,
  getIndustryTypeEnum,
} from '../../../../../services/Auth';
import { GOOGLE_MAP_KEY } from '../../../../../services/Env';
import { setPasswordNew, userLogOut } from '../../../../../services/Profile';
import { SetPasswordValidationSchema } from '../../../../../utils/ValidationSchema/Auth';
import {
  Box,
  IconButton,
  InputAdornment,
  LoadingButton,
  SnackBar,
  TextField,
  Typography,
  makeStyles,
} from '../../../../Containers/index';

Geocode.setApiKey(GOOGLE_MAP_KEY);
Geocode.setLanguage('en');
const useStyles = makeStyles({
  root: {
    '& .MuiLink-root': {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
});
const SetPassword = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [industryMultiTree, setIndustryMultiTree] = useState([]);
  const [contriesList, setContriesList] = useState([]);
  const [toggleForm, setToggleForm] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [cushowPassword, setCuShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [lat, setLat] = useState('');

  const [showRecapcha, setShowRecapcha] = useState(false);
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState('');
  const [lng, setLng] = useState('');

  const { isVerified, reCapchaToken, isUserLoggedIn, tokenState } = useSelector(
    (state) => state?.auth
  );
  function setAddressFieldValue(address_components = [], key, field) {
    let findValue = address_components.find((item) => item.types.includes(key));
    if (findValue) {
      formik.setFieldValue(field, findValue?.long_name);
    } else {
      formik.setFieldValue(field, '');
    }
  }

  const getIndustryType = async () => {
    try {
      const res = await getIndustryTypeEnum();
      if (res) {
        setIndustryMultiTree(res?.data?.data);
      }
    } catch (e) {}
  };
  const getCountriesList = async () => {
    var res = await getCountries();
    if (res) {
      setContriesList(res?.data?.data?.countries);
    }
  };
  const formik = useFormik({
    initialValues: {
      password: '',
      // currentPassword: '',
      confirmPassword: '',
    },
    validationSchema: SetPasswordValidationSchema,
    onSubmit: async (values) => {
      // setShowRecapcha(true);
      setIsLoading(true);
      const params = {
        new_password: values.password,
        // old_password: values.currentPassword,

        source: 'dashboard',

        access_token: tokenState?.idToken,
      };
      localStorage.setItem('idToken', tokenState?.idToken);
      try {
        const response = await setPasswordNew(params);
        if (response.data.status === 'success') {
          dispatch(handleUserLoggedIn(false));
          localStorage.clear();
          await signOut();
          await userLogOut();
          dispatch(handleToken({ idToken: null, accessToken: null }));
          dispatch(
            toggleSnackBar({
              isOpen: true,
              type: 'success',
              message: 'Password updated successfully',
            })
          );
          setTimeout(async () => {
            // Auth.signOut();
            await signOut();
            await userLogOut();
            localStorage.clear();
            navigate(ROUTE.ROOT);
            window.location.reload();
          }, 500);
        }
      } catch (error) {
        localStorage.removeItem('idToken');
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'error',
            message: error,
          })
        );
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (!tokenState?.idToken) {
      navigate('/');
    }
    window.addEventListener('popstate', async function (event) {
      localStorage.clear();
      await signOut();
      await userLogOut();
      navigate('/');
      window.location.reload();
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Box className={classes.root}>
      <SnackBar />

      <Typography
        my={4}
        sx={{
          fontWeight: 900,
          fontSize: '24px',
          fontStyle: 'normal',
        }}
      >
        {t('Set Password')}
      </Typography>

      <Box sx={{ my: 2 }}>
        {/* <TextField
          inputProps={{ 'data-testid': 'signup-password' }}
          fullWidth
          required
          sx={{ mt: 2, mb: 1 }}
          label='Current Password'
          name='currentPassword'
          type={cushowPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => setCuShowPassword(!cushowPassword)}
                  edge='end'
                >
                  {cushowPassword ? (
                    <ICONS.VISIBILITY />
                  ) : (
                    <ICONS.VISIBILITY_OFF />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={
            formik.touched.currentPassword &&
            Boolean(formik.errors.currentPassword)
          }
          value={formik.values.currentPassword
            .trimStart()
            .replace(/\s\s+/g, '')
            .replace(/\p{Emoji_Presentation}/gu, '')}
          onChange={(e) => formik.handleChange(e)}
          helperText={
            formik.touched.currentPassword &&
            formik.errors.currentPassword &&
            t(formik.errors.currentPassword)
          }
        /> */}

        <TextField
          inputProps={{ 'data-testid': 'signup-password' }}
          fullWidth
          required
          sx={{ mt: 2, mb: 1 }}
          label='New Password'
          name='password'
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => setShowPassword(!showPassword)}
                  edge='end'
                >
                  {showPassword ? (
                    <ICONS.VISIBILITY />
                  ) : (
                    <ICONS.VISIBILITY_OFF />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={formik.touched.password && Boolean(formik.errors.password)}
          value={formik.values.password
            .trimStart()
            .replace(/\s\s+/g, '')
            .replace(/\p{Emoji_Presentation}/gu, '')}
          onChange={(e) => formik.handleChange(e)}
          helperText={
            formik.touched.password &&
            formik.errors.password &&
            t(formik.errors.password)
          }
        />

        <TextField
          inputProps={{ 'data-testid': 'signup-confirmPassword' }}
          fullWidth
          sx={{ my: 2 }}
          label='Confirm Password'
          required
          type={showConfirmPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  edge='end'
                >
                  {showConfirmPassword ? (
                    <ICONS.VISIBILITY />
                  ) : (
                    <ICONS.VISIBILITY_OFF />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          name='confirmPassword'
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          value={formik.values.confirmPassword
            .trimStart()
            .replace(/\s\s+/g, '')
            .replace(/\p{Emoji_Presentation}/gu, '')}
          onChange={(e) => formik.handleChange(e)}
          helperText={
            formik.touched.confirmPassword &&
            formik.errors.confirmPassword &&
            t(formik.errors.confirmPassword)
          }
        />

        {/* {errorMessage !== '' && (
            <Typography mt={1} ml={1}>
              {Boolean(errorMessage) && (
                <Box sx={{ color: 'error.main' }}>{errorMessage}</Box>
              )}
            </Typography>
          )} */}
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          fullWidth
          variant='contained'
          onClick={(e) => {
            // handleSecondForm();
            //  const handleCapcha = async (e) => {
            // handleCapcha(e);
            formik.handleSubmit();
          }}
          sx={{
            my: 2,
            textTransform: 'none',
            '& .MuiCircularProgress-root ': {
              color: '#ffff',
            },
          }}
        >
          {t('Confirms')}
        </LoadingButton>
        {/* {showRecapcha && (
          <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
            <TurnstileWidget />
          </Box>
        )} */}
      </Box>
    </Box>
  );
};

export default SetPassword;
