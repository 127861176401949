import { apiGet, apiPost, apiPut } from '../../utils/Axios/axios';
import { AUTH_SERVER_URL } from '../Env';

const PROFILE_URL = AUTH_SERVER_URL + '/profile';
const SET_PASS_URL = PROFILE_URL + '/change_password';
const LOGOUT_URL = AUTH_SERVER_URL + '/proxtera/signout';

export const getProfileDetail = () => {
  return apiGet(PROFILE_URL);
};
export const userLogOut = () => {
  return apiPost(LOGOUT_URL, {});
};
export const setPasswordNew = (apiData) => {
  return apiPut(SET_PASS_URL, apiData);
};
