import { DataGrid } from '@mui/x-data-grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import moment from 'moment';

import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { ICONS } from '../../../../../../constants/Icons';
import { IMAGES } from '../../../../../../constants/ImageConstants';
import { useViewport } from '../../../../../../hooks';
import { getPendingNotifications } from '../../../../../../redux/Reducer/Profile';
import { toggleSnackBar } from '../../../../../../redux/Reducer/Utils';
import { getValidationDetails } from '../../../../../../services/DataValidation';
import {
  getFinancingRequests,
  getRejectedReasons,
  sumbitFinanceRequest,
} from '../../../../../../services/Finance';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  LoadingButton,
  MaterialReactTable,
  MenuItem,
  Select,
  SnackBar,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '../../../../../Containers/index';
const formatDate = (date) => {
  return moment(date).format('DD MMM YYYY');
};
function formatTenure(tenure_in_months, tenure_in_years) {
  // const { tenure_in_years, tenure_in_months } = tenure;

  let result = '';

  if (tenure_in_years > 0) {
    result += `${tenure_in_years} year${tenure_in_years > 1 ? 's' : ''}`;
  }

  if (tenure_in_months > 0) {
    if (result) result += ' and ';
    result += `${tenure_in_months} month${tenure_in_months > 1 ? 's' : ''}`;
  }

  return result || '0 months'; // Default if both are 0
}
function formatString(str) {
  return str
    .split('_') // Split by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' '); // Join with a space
}
function CustomNoRowsOverlay() {
  return (
    <div>
      <Box
        sx={{
          mt: 12,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        No results found.
      </Box>
    </div>
  );
}
const useStyles = makeStyles({
  root: {
    '& .MuiTableRow-root.MuiTableRow-head': {
      backgroundColor: '#17236E !important',
    },
    '& .MuiDataGrid-row--borderBottom ': {
      backgroundColor: '#17236E !important',
    },
    '& li': {
      whiteSpace: 'normal',
    },
    '& .MuiBox-root.caution img': {
      width: '100px',
      height: '100px',
    },
    '& .Mui-TableHeadCell-Content-Wrapper.MuiBox-root': {
      color: '#ffff',
      fontWeight: 400,
    },
    // '& .MuiButtonBase-root.MuiIconButton-root': {
    //   color: '#ffff',
    // },
    '& .MuiTablePagination-actions .Mui-disabled.MuiButtonBase-root.MuiIconButton-root':
      {
        color: '#808080',
      },
    '& .MuiTablePagination-actions .MuiButtonBase-root.MuiIconButton-root': {
      color: 'black',
    },
    // '& .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon':
    //   {
    //     color: '#ffff',
    //   },
    '& a': {
      textDecoration: 'none',
      color: 'black',
    },
    '& a:hover': {
      textDecoration: 'underline',
    },
  },
  // certificate: {
  //   '& certificate a:hover': {
  //     textDecoration: 'underline',
  //   },
  // },
  alert: {
    '& .MuiPaper-root.MuiAlert-root svg': {
      display: 'none',
    },
    '& .MuiPaper-root.MuiAlert-root.success': {
      color: 'white',
      background: '#0A5A25',
    },
    '& .MuiPaper-root.MuiAlert-root.error': {
      color: 'white',
      background: '#CC0000',
    },
  },
  alert1: {
    '& .MuiPaper-root.MuiAlert-root svg': {
      display: 'none',
    },
    '& .MuiPaper-root.MuiAlert-root.success': {
      color: '#237C0C',
      background: '##EDFFE8',
    },
    '& .MuiPaper-root.MuiAlert-root.error': {
      color: 'E0656B',
      background: '#FDEAEA',
    },
  },
});
const NumericFormatCustom = forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix=''
      />
    );
  }
);
const sourceList = [
  {
    id: '',
    status: 'All',
  },
  {
    id: 'GIFE',
    status: 'GIFE',
  },
  {
    id: 'RISE',
    status: 'RISE',
  },
  {
    id: 'invoice',
    status: 'Invoice Financing',
  },
  {
    id: 'purchase_order',
    status: 'Purchase Order Financing',
  },
  {
    id: 'working_capital',
    status: 'Working Capital',
  },
  {
    id: 'letters_of_credit',
    status: 'Letters Of Credit',
  },
];
function Finance() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const [isGIFE, setIsGIFE] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSumbit, setIsLoadingSumbit] = useState(false);
  const [reason, setReason] = useState('');
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [pageCount, setPageCount] = useState(0);

  const [rowCountState, setRowCountState] = useState(pageCount || 0);
  const [validationStatus, setValidationStatus] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  // const [{ pageIndex, pageSize }, setPagination] = useState({
  //   pageIndex: 0,
  //   pageSize: 50,
  // });
  const [value, setValue] = useState(dayjs(new Date()));
  const [isOfferSent, setIsOffersent] = useState('no');

  const [validationData, setValidationData] = useState([]);
  const [rejectedReasons, setRejectedReasons] = useState([]);
  const [currency, setCurrency] = useState('');
  const [tenureList, setTenureList] = useState([
    '1 Month',
    '2 Months',
    '3 Months',
    '4 Months',
    '5 Months',
    '6 Months',
    '7 Months',
    '8 Months',
    '9 Months',
    '10 Months',
    '11 Months',
    '12 Months',
    '13 Months',
    '14 Months',
    '15 Months',
    '16 Months',
    '17 Months',
    '18 Months',
    '19 Months',
    '20 Months',
    '21 Months',
    '22 Months',
    '23 Months',
    '24 Months',
  ]);
  const [currencyList, setCurrencyList] = useState([
    'USD',
    'GBP',
    'EUR',
    'AUD',
    'CAD',
  ]);
  const [tenureMonthList, setTenureMonthList] = useState([
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
  ]);
  const [tenureYearsList, setTenureYearsList] = useState([
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
  ]);
  const [tenure, setTenure] = useState('0');
  const [tenureMonth, setTenureMonth] = useState('0');
  const [tenureYear, setTenureYear] = useState('0');
  const [validationDetails, setValidationDetails] = useState(null);
  const [actionRequired, setActionrequired] = useState(false);
  const [alertType, setAlertType] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isClear, setIsClear] = useState(true);
  const [source, setSource] = useState('');

  const [validationDetailsLoading, setValidationDetailsLoading] =
    useState(false);
  // const [pageCount, setPageCount] = useState(0);
  const [id, setId] = useState('');
  const [loanAmount, setLoanAmount] = useState('');
  const [facilityType, setFacilityType] = useState('');
  const [loanTenure, setLoanTenure] = useState('');
  const [allData, setAllData] = useState([]);

  const [counterPartyDetails, setCounterPartyDetails] = useState(null);
  const [invoiceURLs, setInvoiceURLs] = useState([]);
  const [invoiceFile, setInvoiceFile] = useState([]);
  const [otherDocs, setOtherDocs] = useState([]);
  const [otherDocsData, setOtherDocsData] = useState([]);
  const [otherDocsURL, setOtherDocsURL] = useState('');
  const [otherDocsName, setOtherDocsName] = useState('');
  const [tradeAmount, setTradeAmount] = useState('');

  const { isDBG, isChannel, isBNR, isBDF } = useSelector(
    (state) => state?.auth
  );

  const [approvedAmount, setApprovedAmount] = useState(1);
  const handleSort = async (newSort) => {
    // console.log(newSort);
    if (newSort.length) {
      setSortField(newSort[0].field);
      setSortOrder(newSort[0].sort);
    } else {
      setSortField('');
      setSortOrder('');
    }
  };
  const getRequestDetails = async (id) => {
    try {
      setIsLoading(true);
      setValidationDetailsLoading(true);
      const result = await getValidationDetails(id, 'financing');

      if (result) {
        setAllData(result?.data?.data);
        setAlertType(result?.data?.data);
        setLoanAmount(result?.data?.data?.amount);
        setFacilityType(result?.data?.data?.facility_type);
        if (result?.data?.data?.preferred_tenure?.includes('Months')) {
          setTenure(result?.data?.data?.preferred_tenure);
          setLoanTenure(result?.data?.data?.preferred_tenure);
        } else {
          const temp = result?.data?.data?.preferred_tenure + ' Months';
          // setTenureMonth(result?.data?.data?.preferred_tenure?.split(' ')[0]);
          // setTenureYear(result?.data?.data?.preferred_tenure?.split(' ')[1]);
          setTenure(temp);
          setLoanTenure(temp);
        }

        setApprovedAmount(result?.data?.data?.amount);
        setValidationDetails(result?.data?.data?.data_validation_requests);
        if (result?.data?.data?.file_urls) {
          const invoiceFiles = result?.data?.data?.file_urls?.filter(
            (file) => file.document_type === 'Invoice'
          );
          const otherFiles = result?.data?.data?.file_urls?.filter(
            (file) => file.document_type !== 'Invoice'
          );
          console.log('invoiceFiles', invoiceFiles);
          console.log('otherFiles', otherFiles);
          setInvoiceURLs(invoiceFiles);
          setOtherDocs(otherFiles);
          setIsOffersent('no');
          if (invoiceFiles?.length > 0) {
            console.log('invoiceFiles', invoiceFiles[0]);
            setInvoiceFile(invoiceFiles[0]);
            // setOtherDocsURL(otherFiles[0].document_url);
            // setOtherDocsName(otherFiles[0].document_type);
          }
          if (otherFiles?.length > 0) {
            console.log('otherFiles2', otherFiles[0]);
            setOtherDocsData(otherFiles[0]);
            setOtherDocsURL(otherFiles[0].document_url);
            setOtherDocsName(otherFiles[0].document_type);
          }
        }
        if (result?.data?.data?.counterparty_details) {
          setCounterPartyDetails(result?.data?.data?.counterparty_details);
          setTradeAmount(result?.data?.data?.trade_total_amount);
          setTenureMonth(result?.data?.data?.tenure_in_months);
          setTenureYear(result?.data?.data?.tenure_in_years);
        }
      }
    } catch (error) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: error,
        })
      );
    } finally {
      setValidationDetailsLoading(false);

      setIsLoading(false);
    }
  };
  const requestsColumns = [
    {
      flex: 0.1,
      minWidth: 170,
      sortable: true,
      field: 'source',
      headerName: 'Type of Financing',
      renderCell: ({ row }) =>
        sourceList.find((item) => item.id === row.source)?.status,
    },
    {
      flex: 0.1,
      minWidth: 170,
      sortable: true,
      field: 'company_name',
      headerName: 'Company Name',
    },

    {
      flex: 0.1,
      minWidth: 170,
      sortable: true,
      field: 'date_of_request',
      headerName: 'Application Date',
      renderCell: ({ row }) =>
        `${new Date(row.date_of_request).toLocaleDateString('en-GB')}`,
    },
    {
      flex: 0.1,
      minWidth: 170,
      sortable: true,
      field: 'request_id',
      headerName: 'Application ID',
    },

    // {
    //   flex: 0.1,
    //   minWidth: 170,
    //   sortable: true,
    //   field: 'amount',
    //   headerName: 'Loan Amount (GHS)',
    //   renderCell: ({ row }) =>
    //     `${parseFloat(row.amount || 0)
    //       .toFixed(2)
    //       ?.toString()
    //       .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
    // },
    {
      flex: 0.1,
      minWidth: 200,
      sortable: true,
      field: 'approved_amount',
      headerName: 'Approved Amount (USD)',
      renderCell: ({ row }) =>
        `${
          row.approved_amount === ''
            ? '-'
            : parseFloat(row.approved_amount)
                .toFixed(2)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }`,
    },

    {
      flex: 0.1,
      minWidth: 200,
      width: 200,
      sortable: false,
      field: 'status',
      // enableSorting: false,

      headerName: 'Application Status ',
      renderCell: ({ row }) => {
        if (row.status === 'pending') {
          return (
            <Chip
              sx={{ minWidth: 17, width: 170, maxWidth: 170 }}
              color='error'
              label={row.status?.charAt(0).toUpperCase() + row.status?.slice(1)}
            />
          );
        } else if (row.status === 'validated') {
          return (
            <Chip
              // color='success'
              // sx={{ minWidth: 17, width: 170, maxWidth: 170 }}
              sx={{
                background: '#125688',
                color: 'white',
                minWidth: 17,
                width: 170,
                maxWidth: 170,
              }}
              label={row.status?.charAt(0).toUpperCase() + row.status?.slice(1)}
            />
          );
        } else if (row.status === 'approved') {
          return (
            <Chip
              sx={{ minWidth: 17, width: 170, maxWidth: 170 }}
              color='success'
              // sx={{ background: '#125688', color: 'white' }}
              // label={row.status?.charAt(0).toUpperCase() + row.status?.slice(1)}
              label={'Loan Approved'}
            />
          );
        } else if (row.status === 'repayment') {
          return (
            <Chip
              color='error'
              sx={{ color: 'white', minWidth: 17, width: 170, maxWidth: 170 }}
              // label={row.status?.charAt(0).toUpperCase() + row.status?.slice(1)}
              label={'Repayment'}
            />
          );
        } else if (row.status === 'rejected') {
          return (
            <Chip
              color='error'
              sx={{ color: 'white', minWidth: 17, width: 170, maxWidth: 170 }}
              // label={row.status?.charAt(0).toUpperCase() + row.status?.slice(1)}
              label={'Loan Rejected'}
            />
          );
        } else if (row.status === 'closed') {
          return (
            <Chip
              color='error'
              sx={{ color: 'white', minWidth: 17, width: 170, maxWidth: 170 }}
              // label={row.status?.charAt(0).toUpperCase() + row.status?.slice(1)}
              label={'Loan Closed'}
            />
          );
        } else if (row.status === 'under_review') {
          return (
            <Chip
              // color='success'
              sx={{
                background: 'grey',
                color: 'white',
                minWidth: 17,
                width: 170,
                maxWidth: 170,
              }}
              label={'Under Review'}
              // label={
              //   row.status?.charAt(0).toUpperCase() +
              //   row.status?.slice(1).replace('_', ' ')
              // }
            />
          );
        }
      },
    },
    {
      // accessorKey: 'status',
      flex: 0.1,
      minWidth: 170,
      headerName: 'Action',
      renderCell: ({ row }) => (
        <Button
          onClick={() => {
            setId(row?._id);
            setIsGIFE(row?.source !== 'GIFE');
            getRequestDetails(row?._id);
            dispatch(getPendingNotifications());
            if (row?.status === 'validated' || row?.status === 'under_review') {
              setActionrequired(true);
            } else {
              setActionrequired(false);
            }
          }}
          sx={{
            textTransform: 'capitalize',
            cursor: 'pointer',
            textDecoration: 'underline',
            color:
              row?.status === 'validated' || row?.status === 'under_review'
                ? '#c32e31'
                : 'primaary.main',
          }}
        >
          {row?.status === 'validated' || row?.status === 'under_review'
            ? 'Action Required'
            : 'View Details'}
        </Button>
      ),
    },
  ];
  const detailsColumns = [
    {
      accessorKey: 'data_field',
      header: 'Data Field',
      size: 300,
    },

    {
      accessorKey: 'sme_data',
      header: 'SME Data ',
      size: 100,
      Cell: ({ cell }) => {
        if (cell.row.original.field_name === 'sfe_certificates') {
          let urls = cell.row.original.sme_data.split(',');
          let newUrls = '';
          urls?.map((e) => {
            newUrls += `<a href=${e} target="_blank" >` + e + `</a> <br/>`;
          });
          return (
            <div
              className='certificate'
              dangerouslySetInnerHTML={{ __html: newUrls }}
            />
          );
          //return newUrls;
        } else {
          return `${
            typeof cell.row.original.sme_data === 'object'
              ? cell.row.original.sme_data?.name
              : cell.row.original.sme_data
          }`;
        }
      },
      // accessorFn: (row) =>
      //   `${
      //     typeof row?.sme_data === 'object' ? row.sme_data?.name : row?.sme_data
      //   }`,
    },

    {
      accessorKey: ' ',
      header: 'Is this information correct? ',
      Cell: ({ cell }) => {
        if (
          cell.row.original.field_name !== 'sfe_certificates' &&
          cell.row.original.field_name !== 'coupon_code' &&
          cell.row.original.field_name !== 'current_bank' &&
          cell.row.original.field_name !== 'gender' &&
          cell.row.original.field_name !== 'designation' &&
          cell.row.original.field_name !== 'facility_type' &&
          cell.row.original.field_name !== 'entity_type'
        ) {
          return `${
            cell.row.original.verified_status === 'yes' ? 'Yes' : 'No'
          }`;
        }
      },
      // <FormControl sx={{ m: 1, minWidth: 120 }} disabled={true}>
      //   <Select
      //     // defaultValue={'yes'}
      //     className='MuiOutlinedInput-root	'
      //     variant='standard'
      //     InputProps={{
      //       disableUnderline: false,
      //       readOnly: true,
      //     }}
      //     defaultValue={cell.row.original.verified_status || 'yes'}
      //     onChange={(e) => {
      //       let value = e.target.value;
      //       cell.row.original.verified_status = value;
      //     }}
      //   >
      //     <MenuItem value='yes'>Yes</MenuItem>
      //     <MenuItem value='no'>No</MenuItem>
      //   </Select>
      // </FormControl>
    },
  ];
  const detailsColumnsNonGIFE = [
    {
      accessorKey: 'data_field',
      header: 'Data Field',
      size: 300,
    },

    {
      accessorKey: 'sme_data',
      header: 'SME Data ',
      size: 100,
      Cell: ({ cell }) => {
        if (cell.row.original.field_name === 'sfe_certificates') {
          let urls = cell.row.original.sme_data.split(',');
          let newUrls = '';
          urls?.map((e) => {
            newUrls += `<a href=${e} target="_blank" >` + e + `</a> <br/>`;
          });
          return (
            <div
              className='certificate'
              dangerouslySetInnerHTML={{ __html: newUrls }}
            />
          );
          //return newUrls;
        } else {
          return `${
            typeof cell.row.original.sme_data === 'object'
              ? cell.row.original.sme_data?.name
              : cell.row.original.sme_data
          }`;
        }
      },
      // accessorFn: (row) =>
      //   `${
      //     typeof row?.sme_data === 'object' ? row.sme_data?.name : row?.sme_data
      //   }`,
    },
    {
      accessorKey: 'primary_verifier',
      header: 'Primary Verifier',
      minSize: 130,
      maxSize: 130,
      size: 130,
    },
    {
      accessorKey: 'date',
      header: 'Date',
      Cell: ({ cell }) =>
        cell.row.original.date
          ? moment(cell.row.original.date).format('DD/MM/YYYY')
          : '',
      // minSize: 130,
      // maxSize: 130,
      // size: 130,
    },
    {
      accessorKey: ' ',
      header: 'Is this information correct? ',
      Cell: ({ cell }) => {
        if (
          cell.row.original.field_name !== 'sfe_certificates' &&
          cell.row.original.field_name !== 'coupon_code' &&
          cell.row.original.field_name !== 'current_bank' &&
          cell.row.original.field_name !== 'gender' &&
          cell.row.original.field_name !== 'designation' &&
          cell.row.original.field_name !== 'facility_type' &&
          cell.row.original.field_name !== 'entity_type'
        ) {
          return `${
            cell.row.original.verified_status === 'yes' ? 'Yes' : 'No'
          }`;
        }
      },
      // <FormControl sx={{ m: 1, minWidth: 120 }} disabled={true}>
      //   <Select
      //     // defaultValue={'yes'}
      //     className='MuiOutlinedInput-root	'
      //     variant='standard'
      //     InputProps={{
      //       disableUnderline: false,
      //       readOnly: true,
      //     }}
      //     defaultValue={cell.row.original.verified_status || 'yes'}
      //     onChange={(e) => {
      //       let value = e.target.value;
      //       cell.row.original.verified_status = value;
      //     }}
      //   >
      //     <MenuItem value='yes'>Yes</MenuItem>
      //     <MenuItem value='no'>No</MenuItem>
      //   </Select>
      // </FormControl>
    },
  ];
  const detailsColumnsCounter = [
    {
      accessorKey: 'data_field',
      header: 'Data Field',
      size: 300,
    },

    {
      accessorKey: 'sme_data',
      header: 'SME Data ',
      size: 100,
      Cell: ({ cell }) => {
        if (cell.row.original.field_name === 'sfe_certificates') {
          let urls = cell.row.original.sme_data.split(',');
          let newUrls = '';
          urls?.map((e) => {
            newUrls += `<a href=${e} target="_blank" >` + e + `</a> <br/>`;
          });
          return (
            <div
              className='certificate'
              dangerouslySetInnerHTML={{ __html: newUrls }}
            />
          );
          //return newUrls;
        } else {
          return `${
            typeof cell.row.original.sme_data === 'object'
              ? cell.row.original.sme_data?.name
              : cell.row.original.sme_data
          }`;
        }
      },
      // accessorFn: (row) =>
      //   `${
      //     typeof row?.sme_data === 'object' ? row.sme_data?.name : row?.sme_data
      //   }`,
    },
    {
      accessorKey: 'primary_verifier',
      header: 'Primary Verifier',
      minSize: 130,
      maxSize: 130,
      size: 130,
    },
    {
      accessorKey: 'date',
      header: 'Date',
      Cell: ({ cell }) =>
        cell.row.original.date
          ? moment(cell.row.original.date).format('DD/MM/YYYY')
          : '',
      // minSize: 130,
      // maxSize: 130,
      // size: 130,
    },
    {
      accessorKey: ' ',
      header: 'Is this information correct? ',
      Cell: ({ cell }) => {
        if (
          cell.row.original.field_name !== 'sfe_certificates' &&
          cell.row.original.field_name !== 'coupon_code' &&
          cell.row.original.field_name !== 'current_bank' &&
          cell.row.original.field_name !== 'gender' &&
          cell.row.original.field_name !== 'designation' &&
          cell.row.original.field_name !== 'facility_type' &&
          cell.row.original.field_name !== 'entity_type'
        ) {
          return `${
            cell.row.original.verified_status === 'yes' ? 'Yes' : 'No'
          }`;
        }
      },
      // <FormControl sx={{ m: 1, minWidth: 120 }} disabled={true}>
      //   <Select
      //     // defaultValue={'yes'}
      //     className='MuiOutlinedInput-root	'
      //     variant='standard'
      //     InputProps={{
      //       disableUnderline: false,
      //       readOnly: true,
      //     }}
      //     defaultValue={cell.row.original.verified_status || 'yes'}
      //     onChange={(e) => {
      //       let value = e.target.value;
      //       cell.row.original.verified_status = value;
      //     }}
      //   >
      //     <MenuItem value='yes'>Yes</MenuItem>
      //     <MenuItem value='no'>No</MenuItem>
      //   </Select>
      // </FormControl>
    },
  ];
  const options = [
    {
      id: 'all',
      status: 'All',
    },
    {
      id: 'approved',
      status: 'Approved',
    },
    {
      id: 'validated',
      status: 'Validated',
    },
    {
      id: 'under_review',
      status: 'Under Review',
    },
    {
      id: 'rejected',
      status: 'Rejected',
    },
  ];
  const [statusId, setStatusId] = useState('all');

  const handleStatusChange = (event) => {
    const id = event.target.value;
    setStatusId(id);
    getFinancingRequestsData(id);
  };
  const handleToFChange = (event) => {
    const id = event.target.value;
    setSource(id);
    // getValidationRequests(statusId);
    // getValidationRequests(id);
  };
  const getFinancingRequestsData = async (status) => {
    try {
      setIsLoading(true);
      const params = {
        page: paginationModel?.page + 1,
        size: paginationModel?.pageSize,
        status: status === 'all' ? '' : status,
        search: searchKeyword,
        sort_field: sortField,
        sort_order: sortOrder,
        source: source,
      };
      const result = await getFinancingRequests(params);
      if (result) {
        setValidationData(result?.data?.data?.data);
        setPageCount(parseInt(result?.data?.data?.total));
      }
    } catch (error) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: error,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getFinancingRequestsData(statusId);
  }, [source]);
  // const pagination = useMemo(
  //   () => ({
  //     pageIndex,
  //     pageSize,
  //   }),
  //   [pageIndex, pageSize]
  // );
  const handleChange = (event) => {
    const val = event.target.value;
    // setTenure('0');
    // console.log(val);
    // if (val !== 'approved') {
    //   // setTenure('0');
    // }
    setIsOffersent('no');
    if (val !== '') {
      setApprovedAmount(loanAmount);

      setReason(val);
    }
  };
  useEffect(() => {
    getFinancingRequestsData(statusId);
    // eslint-disable-next-line
  }, [paginationModel, sortOrder, sortField, isClear]);

  const getRejectedReasonsData = async () => {
    try {
      const result = await getRejectedReasons();

      if (result) {
        setRejectedReasons(result?.data?.data);
      }
    } catch (error) {
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: error,
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getRejectedReasonsData();

    // eslint-disable-next-line
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    let params = null;

    if (
      parseInt(approvedAmount) < 1 ||
      approvedAmount === null ||
      approvedAmount === undefined ||
      approvedAmount === '' ||
      tenure === '' ||
      tenure === null
    ) {
    }
    if (reason === 'approved' && !isGIFE) {
      params = {
        request_id: id,
        status: reason,
        approved_amount: approvedAmount,
        approved_tenure: tenure,
      };
    } else if (reason === 'approved' && isGIFE) {
      params = {
        request_id: id,
        status: reason,
        approved_amount: approvedAmount,
        // approved_tenure: tenure,
        approved_currency: currency,
        is_initial_offer_sent: isOfferSent === 'yes' ? true : false,
        offer_letter_sent_at:
          isOfferSent === 'yes' ? dayjs(value).format('YYYY-MM-DD') : '',
        approved_tenure_in_years: tenureYear,
        approved_tenure_in_months: tenureMonth,
      };
    } else {
      params = {
        request_id: id,
        status: 'rejected',
        rejected_reason: reason,
      };
    }
    console.log(params);
    try {
      setIsLoadingSumbit(true);

      const result = await sumbitFinanceRequest(params);
      if (result) {
        dispatch(
          toggleSnackBar({
            isOpen: true,
            type: 'success',
            message: result?.data?.message,
          })
        );
        // if (reason === 'approved') {
        getFinancingRequestsData(statusId);
        // }
        setValidationDetails([]);
        setId('');
      }
    } catch (error) {
      // setIsGIFE(false);
      dispatch(
        toggleSnackBar({
          isOpen: true,
          type: 'error',
          message: error,
        })
      );
    } finally {
      setIsLoadingSumbit(false);
      setReason('');

      dispatch(getPendingNotifications());
    }
  };

  const ScrollToTop = () => {
    const element = document.getElementById('top');
    element.scrollIntoView(
      {
        behavior: 'smooth',
      },
      1000
    );
  };

  useEffect(() => {
    // ScrollToTop();
  }, [validationData]);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageCount !== undefined ? pageCount : prevRowCountState
    );
  }, [pageCount, setRowCountState]);
  // useEffect(() => {
  //   if (searchKeyword.length >= 3) {
  //     getFinancingRequestsData(statusId);
  //   }
  // }, [searchKeyword]);
  return (
    <Grid container className={classes.root} sx={{ mb: 8 }}>
      <SnackBar />
      {id === '' && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              variant='h5'
              component='h3'
              sx={{ fontWeight: 400, px: 8, py: 4, fontSize: 22 }}
              id='top'
            >
              {id === ''
                ? 'Financing Applications'
                : id !== '' &&
                    validationDetails &&
                    validationDetails[0]?.sme_data
                  ? validationDetails[0]?.sme_data
                  : ''}
              {id !== '' && !validationDetailsLoading && !isLoading && (
                <Tooltip title={t('Verified_by_cbg')} placement='right' arrow>
                  <ICONS.CHECKCIRCLE sx={{ color: 'green', mt: 1 }} />
                </Tooltip>
              )}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            {/* {id !== '' && validationDetails && (
              <>
                <Button
                  variant='outlined'
                  sx={{ mr: 8 }}
                  onClick={() => {
                    getFinancingRequestsData(statusId);
                    setValidationDetails([]);
                    dispatch(getPendingNotifications());
                    // dispatch(getPendingNotifications());
                    setId('');
                  }}
                >
                  {t('Back')}
                </Button>
              </>
            )} */}
          </Grid>
        </Grid>
      )}
      {id !== '' && !isGIFE && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              variant='h5'
              component='h3'
              sx={{ fontWeight: 700, px: 8, py: 4 }}
              id='top'
            >
              {id === ''
                ? 'Financing Requests'
                : id !== '' &&
                    validationDetails &&
                    validationDetails[0]?.sme_data
                  ? validationDetails[0]?.sme_data
                  : ''}
              {id !== '' && !validationDetailsLoading && !isLoading && (
                <Tooltip title={t('Verified_by_cbg')} placement='right' arrow>
                  <ICONS.CHECKCIRCLE sx={{ color: 'green', mt: 1 }} />
                </Tooltip>
              )}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            {id !== '' && validationDetails && (
              <>
                <Button
                  variant='outlined'
                  sx={{ mr: 8 }}
                  onClick={() => {
                    getFinancingRequestsData(statusId);
                    setValidationDetails([]);
                    dispatch(getPendingNotifications());
                    // dispatch(getPendingNotifications());
                    setIsOffersent('no');
                    setId('');
                  }}
                >
                  {t('Back')}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      )}
      {id !== '' && isGIFE && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sm={12} lg={6}>
            <Box sx={{ ml: 6, py: 4, display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={() => {
                  dispatch(getPendingNotifications());
                  setId('');
                }}
              >
                <ICONS.CHEVRON_LEFT />
              </IconButton>
              <Box
                sx={{
                  display: 'flex',
                  '& img': {
                    width: '24px !important',
                    height: '24px !important',
                    marginLeft: '10px',
                    mt: '-2px',
                  },
                }}
              >
                <Typography
                  // variant='h5'
                  // component='h3'
                  sx={{
                    fontWeight: 700,
                    ml: 2,
                    fontSize: '16px',
                    color: 'secondary.dark',
                  }}
                >
                  {allData?.corporate_name}
                  {/* {id !== '' && !validationDetailsLoading && !isLoading && ( */}

                  {/* <IMAGES.VERIFY /> */}

                  {/* )} */}
                </Typography>
                <img src={IMAGES.VERIFY} alt='verify' />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            lg={12}
            sx={{ pt: '0px !important' }}
          >
            <Card
              elevation={0}
              sx={{
                borderRadius: 0,
                height: '8px',
                p: 0,
                // p: 2,
                display: 'flex',
                alignItems: 'center',
                background: '#f5f5f5',
              }}
            ></Card>
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <LoadingButton
              // loading={isDraftSumbit}
              // disabled={isDraftSumbit}
              fullWidth={isMobile}
              sx={{
                mx: 8,
                borderRadius: 4,

                textTransform: 'none',
                '& .MuiCircularProgress-root ': {
                  color: '#17236E',
                },
              }}
              variant='outlined'
              // onClick={() => handleDraftGIFE()}
            >
              {t('Save_as_Draft')}
            </LoadingButton>
          </Grid> */}
        </Grid>
      )}
      {id === '' && (
        <Grid container px={8} py={4} spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label='Search'
              placeholder='Search here'
              id='outlined-start-adornment'
              fullWidth
              // sx={{ m: 1, width: '25ch' }}
              value={searchKeyword}
              // sx={{ minWidth: isMobile ? 200 : 440, maxWidth: '100%' }}
              onKeyDown={(ev) => {
                // console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === 'Enter') {
                  // Do code here
                  ev.preventDefault();
                  // if (searchKeyword.length >= 3) {
                  getFinancingRequestsData(statusId);
                  // }
                }
              }}
              onChange={(e) => setSearchKeyword(e.target.value)}
              size='small'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <ICONS.SEARCH />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    {searchKeyword !== '' && (
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => {
                          setSearchKeyword('');
                          setTimeout(() => {
                            setIsClear(!isClear);
                            // getValidationRequests(statusId);
                          }, 500);
                        }}
                        edge='end'
                      >
                        <ICONS.HIGHLIGHTSOFF
                          sx={{
                            color: 'black',
                            fontSize: '16px',
                          }}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl
              // sx={{ minWidth: isMobile ? 235 : 410, maxWidth: '100%' }}
              fullWidth
              size='small'
            >
              <InputLabel id='statusId'>Status</InputLabel>
              <Select
                labelId='statusId'
                id='statusId-select'
                value={statusId}
                label='Status'
                fullWidth
                onChange={handleStatusChange}
              >
                {options.map((item) => {
                  return (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      sx={{ whiteSpace: 'normal' }}
                    >
                      {item.status}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl
              // sx={{ minWidth: isMobile ? 235 : 410, maxWidth: '100%' }}
              fullWidth
              size='small'
            >
              <InputLabel id='tof'>Type of Financing</InputLabel>
              <Select
                labelId='tof'
                id='tof-select'
                value={source}
                label='Type of Financing'
                fullWidth
                onChange={handleToFChange}
              >
                {sourceList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.status}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} md={12} lg={12} sx={{ px: 8, py: 4 }}>
        {id === '' && (
          <DataGrid
            sx={{
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-columnHeaders ': {
                backgroundColor: (theme) => theme.palette.primary.main,
                color: '#fff',
                '& .MuiButtonBase-root.MuiIconButton-root ': {
                  color: '#fff',
                },
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
              },
              '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor': {
                // borderColor: `${theme.palette.primary.main}`,
              },

              '& .MuiDataGrid-columnSeparator ': {
                color: '#fff',
              },
              '& .MuiDataGrid-virtualScroller': {
                // border: `1px solid ${theme.palette.primary.main}`,
                border: `.25px solid grey`,
              },
            }}
            autoHeight
            rowHeight={62}
            rows={validationData}
            loading={isLoading}
            enableHiding={false}
            enableColumnActions={false}
            enableColumnFilterModes={false}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            enableGlobalFilter={false}
            enableTopToolbar={false}
            disableColumnMenu={true}
            // onRowClick={(row) => {
            //   // // console.log(row);
            //   if (row?.row?.total_applications > 0) {
            //     handleApplications(row?.row);
            //   }
            // }}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            onSortModelChange={(newSortModel) => handleSort(newSortModel)}
            getRowId={(row) => row._id}
            columns={requestsColumns}
            disableRowSelectionOnClick
            rowCount={rowCountState}
            paginationMode='server'
            pageSizeOptions={[5, 10, 25, 50, 100]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            columnBuffer={5}
          />
          // <MaterialReactTable
          //   columns={requestsColumns}
          //   data={validationData}
          //   enableHiding={false}
          //   enableDensityToggle={false}
          //   enableColumnActions={false}
          //   enableColumnFilters={false}
          //   enableFullScreenToggle={false}
          //   enableGlobalFilter={false}
          //   enableTopToolbar={false}
          //   manualPagination={true}
          //   rowCount={pageCount}
          //   state={{ pagination, isLoading }}
          //   onPaginationChange={setPagination}
          // />
        )}
        {id !== '' && validationDetailsLoading && isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {id !== '' && validationDetails && !isGIFE && (
          <>
            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4 }}>
              <Button
                variant='outlined'
                onClick={() => {
                  getFinancingRequestsData();
                  setValidationDetails([]);
                  setId('');
                }}
              >
                Back
              </Button>
            </Box> */}
            {id !== '' && !validationDetailsLoading && !isLoading && (
              <Grid
                container
                spacing={4}
                sx={{
                  mb: 2,
                }}
              >
                {id !== '' && loanAmount && (
                  <Grid
                    item
                    xs={12}
                    md={9}
                    lg={9}
                    sx={{
                      display: 'flex',

                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          // display: 'flex',
                          // alignItems: 'center',
                          // alignContent: 'center',
                          // mr: 2,
                        }}
                      >
                        Loan Amount (GHS) :{' '}
                      </Typography>{' '}
                      {parseFloat(loanAmount)
                        .toFixed(2)

                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </Typography>
                  </Grid>
                )}

                {/* </Box> */}
              </Grid>
            )}
            {id !== '' && (
              <Grid
                container
                spacing={4}
                sx={{
                  mb: 4,
                }}
              >
                {facilityType && (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    sx={{
                      display: 'flex',

                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Typography sx={{ display: 'flex' }}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          display: 'flex',
                          // mr: 2,
                        }}
                      >
                        {'  '}
                        Facility type :{' '}
                      </Typography>
                      {'  '} {facilityType}
                    </Typography>
                  </Grid>
                )}
                {loanTenure && (
                  <Grid
                    item
                    xs={12}
                    md={3}
                    lg={3}
                    sx={{
                      display: 'flex',

                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Typography sx={{ display: 'flex' }}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          display: 'flex',
                          // flexDirection: 'row',
                          // mr: 2,
                        }}
                      >
                        {'  '}
                        Loan Tenure :{' '}
                      </Typography>
                      {'  '} {loanTenure}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            {id !== '' &&
              !validationDetailsLoading &&
              !isLoading &&
              !actionRequired && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ my: 4, mr: 8 }}
                  className={classes.alert}
                >
                  {/* <Box display='flex' justifyContent='stretch' mb={2}> */}
                  <Typography

                  // sx={{ fontWeight: 700, px: 8, py: 4 }}
                  >
                    <Alert
                      sx={{ width: 1 }}
                      className={
                        alertType?.status === 'rejected' ? 'error' : 'success'
                      }
                      severity={
                        alertType?.status === 'rejected' ? 'error' : 'success'
                      }
                      iconMapping={{
                        success: '',
                        error: '',
                      }}
                    >
                      <Typography sx={{ fontWeight: 700, pb: 2 }}>
                        {alertType?.status === 'rejected'
                          ? 'Loan Rejected'
                          : 'Loan Approved'}
                      </Typography>
                      <Grid container spacing={4} sx={{ width: 1 }}>
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                          <Typography sx={{ fontSize: 14, pb: 2, width: 450 }}>
                            {alertType?.status === 'rejected'
                              ? alertType?.rejected_reason
                              : `Approved Amount : ${parseFloat(
                                  alertType?.approved_amount
                                )
                                  .toFixed(2)
                                  ?.toString()
                                  .replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                  )} GHS    `}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                          {alertType?.status !== 'rejected' && (
                            <Typography sx={{ fontSize: 14, pb: 2 }}>
                              {'  '} Approved Tenure :{' '}
                              {alertType?.approved_tenure}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      {/* <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography sx={{ fontSize: 14, pb: 2 }}>
                          {alertType?.status === 'rejected'
                            ? alertType?.rejected_reason
                            : `Approved Amount : ${parseFloat(
                                alertType?.approved_amount
                              )
                                .toFixed(2)
                                ?.toString()
                                .replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                                )} GHS    `}
                        </Typography>
                        {alertType?.status !== 'rejected' && (
                          <Typography sx={{ fontSize: 14, pb: 2, ml: 4 }}>
                            {'  '} Approved Tenure :{' '}
                            {alertType?.approved_tenure}
                          </Typography>
                        )}
                      </Box> */}
                    </Alert>
                  </Typography>
                  {/* </Box> */}
                </Grid>
              )}

            <MaterialReactTable
              columns={detailsColumns}
              data={validationDetails}
              enableHiding={false}
              enableDensityToggle={false}
              enableColumnActions={false}
              enableColumnFilters={false}
              enableFullScreenToggle={false}
              enableGlobalFilter={false}
              enableTopToolbar={false}
              enablePagination={false}
              // manualPagination={true}
              // rowCount={pageCount}
              state={{ isLoading }}
              // onPaginationChange={setPagination}
            />
          </>
        )}
        {id !== '' &&
          !isGIFE &&
          !validationDetailsLoading &&
          !isLoading &&
          actionRequired &&
          !isDBG &&
          !isChannel &&
          !isBDF &&
          !isBNR && (
            <>
              <Typography
                variant='h5'
                component='h3'
                sx={{ fontWeight: 700, pt: 8, pb: 2, mb: 8 }}
              >
                Action
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                  <Box display='flex' justifyContent='flex-start'>
                    <FormControl
                      sx={{
                        width: 1,
                        maxWidth: '100%',
                        // minWidth: isMobile ? 200 : 410,
                        // maxWidth: '100%',
                      }}
                    >
                      <InputLabel id='reason'>Actions</InputLabel>
                      <Select
                        labelId='reason'
                        id='reason-select'
                        value={reason}
                        label='Select'
                        fullWidth
                        onChange={(e) => handleChange(e)}
                        sx={{}}
                      >
                        <MenuItem key={1} value='approved'>
                          Approve
                        </MenuItem>
                        {rejectedReasons.map((item) => {
                          return (
                            <MenuItem
                              key={item._id}
                              value={item.name}
                              sx={{ whiteSpace: 'normal' }}
                            >
                              Reject - {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  {reason === 'approved' && (
                    <Box display='flex' justifyContent='flex-start'>
                      <FormControl
                        sx={{
                          // minWidth: isMobile ? 200 : 280,
                          width: 1,
                          maxWidth: '100%',
                        }}
                      >
                        {/* <InputLabel id='approvedAmount'>{t('Amount')}</InputLabel>s */}
                        <TextField
                          inputProps={{
                            'data-testid': 'signup-email',
                          }}
                          fullWidth
                          required
                          // labelId='approvedAmount'
                          label={`${t('Amount')} (GHS)`}
                          name='approvedAmount'
                          // type='number'
                          // sx={{ my: 2 }}
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                          }}
                          error={
                            parseInt(approvedAmount) < 1 ||
                            approvedAmount === null ||
                            approvedAmount === undefined ||
                            approvedAmount === '' ||
                            parseInt(approvedAmount) > parseInt(loanAmount)
                          }
                          value={approvedAmount}
                          onChange={(e) => {
                            // const re = /^[0-9\b]+$/;
                            // if (re.test(e.target.value) || e.target.value.length===1) {
                            setApprovedAmount(
                              e.target.value
                                ?.trimStart()
                                .replace(/\s\s+/g, '')
                                .replace(/[^0-9.]+/g, '')
                            );
                            // }
                          }}
                          helperText={
                            parseInt(approvedAmount) < 1 ||
                            approvedAmount === null ||
                            approvedAmount === undefined ||
                            approvedAmount === '' ||
                            (parseInt(approvedAmount) > parseInt(loanAmount) &&
                              t('Approved_Amount_Validation'))
                          }
                        />
                      </FormControl>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  {reason === 'approved' && (
                    <Box display='flex' justifyContent='flex-start'>
                      <FormControl
                        sx={{
                          width: 1,
                          maxWidth: '100%',
                        }}
                        fullWidth
                        // size='small'
                      >
                        <InputLabel id='tebure'>Tenure *</InputLabel>
                        <Select
                          labelId='tebure'
                          id='tebure-select'
                          value={tenure}
                          label='Tenure *'
                          fullWidth
                          onChange={(e) => setTenure(e.target.value)}
                        >
                          {tenureList?.map((item) => {
                            return (
                              <MenuItem
                                key={item}
                                value={item}
                                sx={{ whiteSpace: 'normal' }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>
                          Please note loan tenure is between 1-24 months.
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {reason !== '' && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: {
                          xs: 'flex-start',
                          md: 'flex-start',
                          lg: 'flex-end',
                          xl: 'flex-end',
                        },
                      }}
                      mt={4}
                    >
                      <FormControl
                        sx={{
                          minWidth: 200,
                          width: {
                            xs: 1,
                            md: 1,
                            lg: 0.1,
                            xl: 0.1,
                          },
                        }}
                      >
                        <LoadingButton
                          loading={isLoadingSumbit}
                          disabled={
                            parseInt(approvedAmount) < 1 ||
                            approvedAmount === null ||
                            approvedAmount === undefined ||
                            approvedAmount === '' ||
                            parseInt(approvedAmount) > parseInt(loanAmount) ||
                            isLoadingSumbit ||
                            tenure === '' ||
                            tenure === null
                          }
                          fullWidth
                          variant='contained'
                          onClick={handleSubmit}
                          sx={{
                            // my: 2,
                            textTransform: 'none',
                            '& .MuiCircularProgress-root ': {
                              color: '#ffff',
                            },
                          }}
                        >
                          {t('Submit')}
                        </LoadingButton>
                      </FormControl>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </>
          )}

        <Grid item xs={12} md={12} lg={12} sx={{ px: 0, py: 4 }}>
          {id !== '' && validationDetails && isGIFE && (
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                mb: 2,
              }}
            >
              <Grid container spacing={2}>
                {/* <Box display='flex' justifyContent='flex-start' mb={2}> */}
                {id !== '' && (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={2}
                    sx={{
                      display: 'flex',

                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Card
                      elevation={0}
                      sx={{
                        width: '100%',
                      }}
                    >
                      <CardContent sx={{ '&:last-child': { pb: 4 } }}>
                        <Typography
                          gutterBottom
                          sx={{
                            fontWeight: 400,
                            fontSize: '12px',
                            color: 'secondary.dark',
                          }}
                        >
                          Loan Request
                        </Typography>
                        <Typography
                          variant='h5'
                          sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            color: 'secondary.contrastText',
                          }}
                        >
                          {formatString(allData?.source)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {id !== '' && (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={3}
                    sx={{
                      display: 'flex',

                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Card
                      elevation={0}
                      sx={{
                        width: '100%',
                      }}
                    >
                      <CardContent sx={{ '&:last-child': { pb: 4 } }}>
                        <Typography
                          gutterBottom
                          sx={{
                            fontWeight: 400,
                            fontSize: '12px',
                            color: 'secondary.dark',
                          }}
                        >
                          Application ID
                        </Typography>
                        <Typography
                          variant='h5'
                          sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            color: 'secondary.contrastText',
                          }}
                        >
                          {allData?.application_id}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {id !== '' && (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={2}
                    sx={{
                      display: 'flex',

                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Card
                      elevation={0}
                      sx={{
                        width: '100%',
                      }}
                    >
                      <CardContent sx={{ '&:last-child': { pb: 4 } }}>
                        <Typography
                          gutterBottom
                          sx={{
                            fontWeight: 400,
                            fontSize: '12px',
                            color: 'secondary.contrastText',
                          }}
                        >
                          Date of Request
                        </Typography>
                        <Typography
                          variant='h5'
                          sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            color: 'secondary.contrastText',
                          }}
                        >
                          {formatDate(allData?.date_of_request)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={4}>
                {/* <Box display='flex' justifyContent='flex-start' mb={2}> */}
                {id !== '' && loanAmount && (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={2}
                    sx={{
                      display: 'flex',

                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Card elevation={4} sx={{ width: '100%' }}>
                      <CardContent sx={{ '&:last-child': { pb: 4 } }}>
                        <Typography
                          gutterBottom
                          sx={{
                            fontWeight: 400,
                            fontSize: '12px',
                            color: 'secondary.contrastText',
                          }}
                        >
                          Loan Amount ({allData?.currency})
                        </Typography>
                        <Typography
                          variant='h5'
                          sx={{
                            fontWeight: 400,
                            fontSize: '20px',
                            color: 'secondary.dark',
                          }}
                        >
                          {parseFloat(loanAmount)
                            .toFixed(2)

                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {id !== '' && tradeAmount && (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={2}
                    sx={{
                      display: 'flex',

                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Card elevation={4} sx={{ width: '100%' }}>
                      <CardContent sx={{ '&:last-child': { pb: 4 } }}>
                        <Typography
                          gutterBottom
                          sx={{
                            fontWeight: 400,
                            fontSize: '12px',
                            color: 'secondary.contrastText',
                          }}
                        >
                          Trade Document Total ({allData?.trade_total_currency})
                        </Typography>
                        <Typography
                          variant='h5'
                          sx={{
                            fontWeight: 400,
                            fontSize: '20px',
                            color: 'secondary.dark',
                          }}
                        >
                          {parseFloat(tradeAmount)
                            .toFixed(2)

                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {id !== '' && loanAmount && (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={3}
                    sx={{
                      display: 'flex',

                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Card elevation={4} sx={{ width: '100%' }}>
                      <CardContent sx={{ '&:last-child': { pb: 4 } }}>
                        <Typography
                          gutterBottom
                          sx={{
                            fontWeight: 400,
                            fontSize: '12px',
                            color: 'secondary.contrastText',
                          }}
                        >
                          Loan Tenure
                        </Typography>
                        <Typography
                          variant='h5'
                          sx={{
                            fontWeight: 400,
                            fontSize: '20px',
                            color: 'secondary.dark',
                          }}
                        >
                          {formatTenure(
                            allData?.tenure_in_months,
                            allData?.tenure_in_years
                          )}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {id !== '' && invoiceURLs?.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={2}
                    sx={{
                      display: 'flex',

                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Card elevation={0} sx={{ width: '100%' }}>
                      <CardContent sx={{ '&:last-child': { pb: 4 } }}>
                        <Typography
                          gutterBottom
                          sx={{
                            fontWeight: 400,
                            fontSize: '12px',
                            mb: 4,
                            color: 'secondary.contrastText',
                          }}
                        >
                          Invoice
                        </Typography>
                        <ListItem
                          sx={{
                            border: '1px solid #E0E0E0',
                            borderRadius: '4px',
                            height: '40px',
                            // mt: 4,
                          }}
                          secondaryAction={
                            <IconButton
                              aria-label='toggle password visibility'
                              // onClick={handleClickShowPassword}
                              onClick={() => {
                                if (invoiceFile?.document_url) {
                                  window.open(
                                    invoiceFile?.document_url,
                                    '_blank',
                                    'noopener,noreferrer'
                                  );
                                }
                              }}
                              // onMouseDown={handleMouseDownPassword}
                              edge='end'
                            >
                              <ICONS.FILE_DOWNLOAD />
                            </IconButton>
                          }
                        >
                          <TextField
                            select
                            fullWidth
                            // defaultValue='Document'
                            value={invoiceFile}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setInvoiceFile(e.target.value);
                              // setOtherDocsURL(e.target.value?.document_url);
                              // setOtherDocsName(e.target.value?.document_type);
                            }}
                            variant='standard'
                            size='normal'
                            // disabled
                            InputProps={{
                              disableUnderline: true, // Remove underline
                            }}
                          >
                            {invoiceURLs?.map((option) => (
                              <MenuItem
                                key={option._id}
                                // selected={otherDocsURL}
                                // value2={option}
                                value={option}
                              >
                                {option.file_name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </ListItem>
                      </CardContent>
                    </Card>
                    {/* <Card elevation={0} sx={{ width: '100%' }}>
                      <CardContent sx={{ '&:last-child': { pb: 4 } }}>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 400, fontSize: '12px', mb: 4 }}
                        >
                          Invoice
                        </Typography>
                        <TextField
                          defaultValue='Document'
                          variant='outlined'
                          size='small'
                          disabled
                          sx={{
                            '& .MuiOutlinedInput-root .Mui-disabled': {
                              '-webkit-text-fill-color': 'black !important',
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  onClick={() => {
                                    if (
                                      invoiceURLs.length > 0 &&
                                      invoiceURLs[0].document_url
                                    ) {
                                      window.open(
                                        invoiceURLs[0].document_url,
                                        '_blank',
                                        'noopener,noreferrer'
                                      );
                                    }
                                  }}
                                  // onMouseDown={handleMouseDownPassword}
                                  edge='end'
                                >
                                  <ICONS.FILE_DOWNLOAD />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </CardContent>
                    </Card> */}
                  </Grid>
                )}
                {id !== '' && otherDocs?.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={3}
                    sx={{
                      display: 'flex',

                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Card elevation={0} sx={{ width: '100%' }}>
                      <CardContent sx={{ '&:last-child': { pb: 4 } }}>
                        <Typography
                          gutterBottom
                          sx={{
                            fontWeight: 400,
                            fontSize: '12px',
                            mb: 4,
                            color: 'secondary.contrastText',
                          }}
                        >
                          Supporting Documents
                        </Typography>
                        <ListItem
                          sx={{
                            border: '1px solid #E0E0E0',
                            borderRadius: '4px',
                            height: '40px',
                            // mt: 4,
                          }}
                          secondaryAction={
                            <IconButton
                              aria-label='toggle password visibility'
                              // onClick={handleClickShowPassword}
                              onClick={() => {
                                if (otherDocsData?.document_url) {
                                  window.open(
                                    otherDocsData?.document_url,
                                    '_blank',
                                    'noopener,noreferrer'
                                  );
                                }
                              }}
                              // onMouseDown={handleMouseDownPassword}
                              edge='end'
                            >
                              <ICONS.FILE_DOWNLOAD />
                            </IconButton>
                          }
                        >
                          <TextField
                            select
                            fullWidth
                            // defaultValue='Document'
                            value={otherDocsData}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setOtherDocsData(e.target.value);
                              setOtherDocsURL(e.target.value?.document_url);
                              setOtherDocsName(e.target.value?.document_type);
                            }}
                            variant='standard'
                            // size='small'
                            // disabled
                            InputProps={{
                              disableUnderline: true, // Remove underline
                            }}
                          >
                            {otherDocs?.map((option) => (
                              <MenuItem
                                key={option._id}
                                // selected={otherDocsURL}
                                // value2={option}
                                value={option}
                              >
                                {option.document_type}
                              </MenuItem>
                            ))}
                          </TextField>
                        </ListItem>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {/* </Box> */}
              </Grid>
            </Grid>
          )}
          {id !== '' &&
            !validationDetailsLoading &&
            !isLoading &&
            !actionRequired &&
            validationDetails &&
            isGIFE && (
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ my: 4, mr: 8 }}
                className={classes.alert1}
              >
                {/* <Box display='flex' justifyContent='stretch' mb={2}> */}
                <Typography

                // sx={{ fontWeight: 700, px: 8, py: 4 }}
                >
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert
                      sx={{ width: 1 }}
                      className={
                        alertType?.status === 'rejected' ? 'error' : 'success'
                      }
                      severity={
                        alertType?.status === 'rejected' ? 'error' : 'success'
                      }
                      iconMapping={{
                        success: '',
                        error: '',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          '& img': {
                            width: '24px !important',
                            height: '24px !important',
                            marginLeft: '10px',
                            mt: '-2px',
                          },
                        }}
                      >
                        <Typography sx={{ fontWeight: 700, pb: 2 }}>
                          {alertType?.status === 'rejected'
                            ? 'Loan Rejected'
                            : 'Loan Approved'}
                        </Typography>
                        {alertType?.status !== 'rejected' && (
                          <img src={IMAGES.VERIFY} alt='verify' />
                        )}
                      </Box>
                      <Grid container spacing={4} sx={{ width: 1 }}>
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                          <Typography sx={{ fontSize: 14, pb: 2, width: 450 }}>
                            {alertType?.status === 'rejected'
                              ? alertType?.rejected_reason
                              : `Loan Amount (USD) : ${parseFloat(
                                  alertType?.approved_amount
                                )
                                  .toFixed(2)
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}   `}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                          {alertType?.status === 'rejected' && (
                            <Typography sx={{ fontSize: 14, pb: 2 }}>
                              {'  '} Loan Tenure :{' '}
                              {formatTenure(
                                allData?.tenure_in_months,
                                allData?.tenure_in_years
                              )}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      {/* <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography sx={{ fontSize: 14, pb: 2 }}>
                          {alertType?.status === 'rejected'
                            ? alertType?.rejected_reason
                            : `Approved Amount : ${parseFloat(
                                alertType?.approved_amount
                              )
                                .toFixed(2)
                                ?.toString()
                                .replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                                )} GHS    `}
                        </Typography>
                        {alertType?.status !== 'rejected' && (
                          <Typography sx={{ fontSize: 14, pb: 2, ml: 4 }}>
                            {'  '} Approved Tenure :{' '}
                            {alertType?.approved_tenure}
                          </Typography>
                        )}
                      </Box> */}
                    </Alert>
                  </Stack>
                </Typography>
                {/* </Box> */}
              </Grid>
            )}
          {id !== '' && validationDetails && isGIFE && (
            <>
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    pt: 4,
                    fontSize: '16px',
                    color: 'secondary.dark',
                  }}
                >
                  {'Business Details'}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    // py: 4,
                    mb: 2,
                    fontSize: '12px',
                    color: 'secondary.dark',
                  }}
                >
                  {'Validated by: '}{' '}
                  {allData?.validatedBy !== null
                    ? allData?.validatedBy?.first_name +
                      ' ' +
                      allData?.validatedBy?.last_name
                    : 'NA'}
                </Typography>
              </Grid>
              <MaterialReactTable
                columns={detailsColumnsNonGIFE}
                data={validationDetails}
                layoutMode='grid'
                enableHiding={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableGlobalFilter={false}
                enableTopToolbar={false}
                enablePagination={false}
                // manualPagination={true}
                // rowCount={pageCount}
                state={{ isLoading }}

                // onPaginationChange={setPagination}
              />
            </>
          )}

          {id !== '' && counterPartyDetails && isGIFE && (
            <>
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    pt: 4,
                    fontSize: '16px',
                    color: 'secondary.dark',
                  }}
                >
                  {'Counterparty Details'}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    mb: 2,
                    fontSize: '12px',
                    color: 'secondary.dark',
                  }}
                >
                  {'Validated by: '}{' '}
                  {allData?.validatedBy !== null
                    ? allData?.validatedBy?.first_name +
                      ' ' +
                      allData?.validatedBy?.last_name
                    : 'NA'}
                </Typography>
              </Grid>
              <MaterialReactTable
                columns={detailsColumnsCounter}
                data={counterPartyDetails}
                layoutMode='grid'
                enableHiding={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableGlobalFilter={false}
                enableTopToolbar={false}
                enablePagination={false}
                // manualPagination={true}
                // rowCount={pageCount}
                state={{ isLoading }}

                // onPaginationChange={setPagination}
              />
            </>
          )}
        </Grid>
        {id !== '' &&
          isGIFE &&
          !validationDetailsLoading &&
          !isLoading &&
          actionRequired &&
          !isDBG &&
          !isChannel &&
          !isBDF &&
          !isBNR && (
            <>
              <Typography
                variant='h5'
                component='h3'
                sx={{ fontWeight: 700, pt: 8, pb: 2, mb: 8 }}
              >
                Action
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                  <Box display='flex' justifyContent='flex-start'>
                    <FormControl
                      sx={{
                        width: 1,
                        maxWidth: '100%',
                        // minWidth: isMobile ? 200 : 410,
                        // maxWidth: '100%',
                      }}
                    >
                      <InputLabel id='reason'>Actions</InputLabel>
                      <Select
                        labelId='reason'
                        id='reason-select'
                        value={reason}
                        label='Select'
                        fullWidth
                        onChange={(e) => handleChange(e)}
                        sx={{}}
                      >
                        <MenuItem key={1} value='approved'>
                          Approve
                        </MenuItem>
                        {rejectedReasons.map((item) => {
                          return (
                            <MenuItem
                              key={item._id}
                              value={item.name}
                              sx={{ whiteSpace: 'normal' }}
                            >
                              Reject - {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  {reason === 'approved' && (
                    <Box display='flex' justifyContent='flex-start'>
                      <FormControl
                        sx={{
                          width: 1,
                          maxWidth: '100%',
                        }}
                        fullWidth
                        // size='small'
                      >
                        <InputLabel id='tebure'>Currency *</InputLabel>
                        <Select
                          labelId='tebure'
                          id='tebure-select'
                          value={currency}
                          label='Currency *'
                          fullWidth
                          onChange={(e) => setCurrency(e.target.value)}
                        >
                          {currencyList?.map((item) => {
                            return (
                              <MenuItem
                                key={item}
                                value={item}
                                sx={{ whiteSpace: 'normal' }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {/* <FormHelperText>
                          Please note loan tenure is between 1-24 months.
                        </FormHelperText> */}
                      </FormControl>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  {reason === 'approved' && (
                    <Box display='flex' justifyContent='flex-start'>
                      <FormControl
                        sx={{
                          // minWidth: isMobile ? 200 : 280,
                          width: 1,
                          maxWidth: '100%',
                        }}
                      >
                        {/* <InputLabel id='approvedAmount'>{t('Amount')}</InputLabel>s */}
                        <TextField
                          inputProps={{
                            'data-testid': 'signup-email',
                          }}
                          fullWidth
                          required
                          // labelId='approvedAmount'
                          label={`${t('Amount')}`}
                          name='approvedAmount'
                          // type='number'
                          // sx={{ my: 2 }}
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                          }}
                          error={
                            parseInt(approvedAmount) < 1 ||
                            approvedAmount === null ||
                            approvedAmount === undefined ||
                            approvedAmount === '' ||
                            parseInt(approvedAmount) > parseInt(loanAmount)
                          }
                          value={approvedAmount}
                          onChange={(e) => {
                            // const re = /^[0-9\b]+$/;
                            // if (re.test(e.target.value) || e.target.value.length===1) {
                            setApprovedAmount(
                              e.target.value
                                ?.trimStart()
                                .replace(/\s\s+/g, '')
                                .replace(/[^0-9.]+/g, '')
                            );
                            // }
                          }}
                          helperText={
                            parseInt(approvedAmount) < 1 ||
                            approvedAmount === null ||
                            approvedAmount === undefined ||
                            approvedAmount === '' ||
                            (parseInt(approvedAmount) > parseInt(loanAmount) &&
                              t('Approved_Amount_Validation'))
                          }
                        />
                      </FormControl>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  {reason === 'approved' && (
                    <Box display='flex' justifyContent='flex-start'>
                      <FormControl
                        sx={{
                          width: 1,
                          maxWidth: '100%',
                        }}
                        fullWidth
                        // size='small'
                      >
                        <InputLabel id='tebure'>Tenure (Years) *</InputLabel>
                        <Select
                          labelId='tebure'
                          id='tebure-select'
                          value={tenureYear}
                          label='Tenure (Years) *'
                          fullWidth
                          onChange={(e) => setTenureYear(e.target.value)}
                        >
                          {tenureYearsList?.map((item) => {
                            return (
                              <MenuItem
                                key={item}
                                value={item}
                                sx={{ whiteSpace: 'normal' }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {/* <FormHelperText>
                          Please note loan tenure is between 1-24 months.
                        </FormHelperText> */}
                      </FormControl>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  {reason === 'approved' && (
                    <Box display='flex' justifyContent='flex-start'>
                      <FormControl
                        sx={{
                          width: 1,
                          maxWidth: '100%',
                        }}
                        fullWidth
                        // size='small'
                      >
                        <InputLabel id='tebure'>Tenure (Months) *</InputLabel>
                        <Select
                          labelId='tebure'
                          id='tebure-select'
                          value={tenureMonth}
                          label='Tenure (Months) *'
                          fullWidth
                          onChange={(e) => setTenureMonth(e.target.value)}
                        >
                          {tenureMonthList?.map((item) => {
                            return (
                              <MenuItem
                                key={item}
                                value={item}
                                sx={{ whiteSpace: 'normal' }}
                              >
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {/* <FormHelperText>
                          Please note loan tenure is between 1-24 months.
                        </FormHelperText> */}
                      </FormControl>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={4} mt={2}>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  {reason === 'approved' && (
                    <FormControl>
                      <FormLabel id='demo-controlled-radio-buttons-group'>
                        Has the initial offer been sent to the SME?{' '}
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={isOfferSent}
                        onChange={(e) => setIsOffersent(e.target.value)}
                      >
                        <FormControlLabel
                          value={'yes'}
                          control={<Radio />}
                          label='Yes'
                        />
                        <FormControlLabel
                          value={'no'}
                          control={<Radio />}
                          label='No'
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={4} mt={2}>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  {isOfferSent === 'yes' && (
                    // <Box display='flex' justifyContent='flex-start'>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      sx={{ pt: '6px' }}
                    >
                      <DemoContainer
                        components={['MobileDatePicker']}
                        sx={{ pt: '6px' }}
                      >
                        <MobileDatePicker
                          fullWidth
                          sx={{
                            // mt: 2,
                            '& .MuiFormLabel-root label': {
                              top: '2px !important',
                            },
                          }}
                          maxDate={dayjs(new Date())}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                          label='Date of the offer letter sent *'
                          value={value}
                          onChange={(newValue) => setValue(newValue)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    // </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {reason !== '' && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: {
                          xs: 'flex-start',
                          md: 'flex-start',
                          lg: 'flex-end',
                          xl: 'flex-end',
                        },
                      }}
                      mt={4}
                    >
                      <FormControl
                        sx={{
                          minWidth: 200,
                          width: {
                            xs: 1,
                            md: 1,
                            lg: 0.1,
                            xl: 0.1,
                          },
                        }}
                      >
                        <LoadingButton
                          loading={isLoadingSumbit}
                          disabled={
                            currency === '' ||
                            parseInt(approvedAmount) < 1 ||
                            approvedAmount === null ||
                            approvedAmount === undefined ||
                            approvedAmount === '' ||
                            parseInt(approvedAmount) > parseInt(loanAmount) ||
                            isLoadingSumbit ||
                            tenure === '' ||
                            tenure === null
                          }
                          fullWidth
                          variant='contained'
                          onClick={handleSubmit}
                          sx={{
                            // my: 2,
                            textTransform: 'none',
                            '& .MuiCircularProgress-root ': {
                              color: '#ffff',
                            },
                          }}
                        >
                          {t('Submit')}
                        </LoadingButton>
                      </FormControl>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        {
          // <Grid item xs={12} md={12} lg={12} sx={{}}>
          //   <Box display='flex' justifyContent='flex-start' mt={4}>
          //     <Typography>Note: All the amounts are in GHS</Typography>
          //   </Box>
          // </Grid>
        }
      </Grid>
    </Grid>
  );
}

export default Finance;
