import {
  AccountCircle,
  AddCircle,
  Article,
  CheckCircleOutline,
  ChevronLeft,
  ChevronRight,
  Clear,
  CloseOutlined,
  Code,
  DashboardOutlined,
  Delete,
  DeleteOutline,
  Edit,
  Email,
  FileDownloadOutlined,
  GetApp,
  GroupAddOutlined,
  Home,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Logout,
  Menu,
  PaymentsOutlined,
  PersonOutline,
  PublicOutlined,
  SchoolOutlined,
  Search,
  SettingsOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import DataValidation from './DataValidation';

// import DataValidation from '../../123.svg';
// import DevDashboardIcon from './DevDashboardIcon';
import FinancingRequestIcon from './FinancingIcon';
import HandShake from './HandShake';
import VerifiedCredentialsIcon from './VerifiedCredentials';

export const ICONS = {
  ARROW_DOWN: KeyboardArrowDown,
  ARROW_UP: KeyboardArrowUp,
  VISIBILITY: Visibility,
  VISIBILITY_OFF: VisibilityOff,
  DASHBOARD_ICON: DashboardOutlined,
  LOGOUT_ICON: Logout,
  CHEVRON_LEFT: ChevronLeft,
  CHEVRON_RIGHT: ChevronRight,
  MENU: Menu,
  SCHOOL: SchoolOutlined,
  SETTINGS: SettingsOutlined,
  HOME: Home,
  DOWNLOAD: GetApp,
  TRUSTED_CREDENTIALS: VerifiedCredentialsIcon,
  DATA_VALIDATION: DataValidation,
  FINANCING: FinancingRequestIcon,
  CLOSE: CloseOutlined,
  CHECKCIRCLE: CheckCircleOutline,
  HIGHLIGHTSOFF: Clear,
  SEARCH: Search,
  PROFILE: PersonOutline,
  EDIT: Edit,
  EMAIL: Email,
  ACCOUNTS_CIRCLE: AccountCircle,
  ARTICLE: Article,
  ADD_CIRCLE: AddCircle,
  DELETE: Delete,
  DELETE_OUTLINED: DeleteOutline,
  HANDSHAKE: HandShake,
  DEV_DASHBOARD: Code,
  GROUP_ADD: GroupAddOutlined,
  IMACT_ICON: PublicOutlined,
  PAYMENTS: PaymentsOutlined,
  FILE_DOWNLOAD: FileDownloadOutlined,
};
