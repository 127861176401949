// import { Auth } from 'aws-amplify';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { userLogOut } from '../../services/Profile';
const handleSignOut = async () => {
  await signOut();
  await userLogOut();
};
const CryptoJS = require('crypto-js');
const encrypt = (text) =>
  CryptoJS.AES.encrypt(
    text,
    `${process.env.REACT_APP_CRYPTO_SECRET_KEY}`
  ).toString();
const generateRandomId = () => {
  const proxtera = 'proxtera';
  const idLength = 50;
  let randomId = '';

  const proxteraPosition = Math.floor(
    Math.random() * (idLength - proxtera.length)
  );

  for (let i = 0; i < idLength; i++) {
    if (i === proxteraPosition) {
      randomId += proxtera;
      i += proxtera.length - 1;
    } else {
      const randomChar = Math.random().toString(36).substring(2, 3);
      randomId += randomChar;
    }
  }

  const currentTime = new Date().getTime();
  const futureTime = currentTime + 60 * 1000;
  const timestampString = futureTime.toString();

  randomId =
    randomId.substring(0, idLength - timestampString.length) + timestampString;

  return randomId;
};

// const refreshToken = async () => {
//   const params = {
//     grant_type: 'refresh_token',
//     client_id: process.env.REACT_APP_CLIENT_ID,
//     redirect_uri: window.location.origin,
//     refresh_token: localStorage.getItem('refresh_token'),
//   };

//   const data = Object.keys(params)
//     .map((key) => `${key}=${encodeURIComponent(params[key])}`)
//     .join('&');
//   const options = {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded',
//       Authorization: `Basic ${process.env.REACT_APP_AUTH_TOKEN}`,
//     },
//     data,
//     url: process.env.REACT_APP_AUTH_GET_TOKEN_URL,
//   };
//   await axios(options).then(
//     (res) => {
//       localStorage.setItem('access_token', res?.data?.access_token);
//       localStorage.setItem('idToken', res?.data?.id_token);
//     },
//     (err) => {
//       // console.log('error=======>', err);
//     }
//   );
// };

export const refreshToken = async () => {
  const { tokens } = await fetchAuthSession({ forceRefresh: true });
  const { idToken, accessToken } = tokens;
  localStorage.setItem('idToken', idToken?.toString());
  localStorage.setItem('access_token', accessToken?.toString());
};
export const getToken = async () => {
  // TODO to add refresh token logout flow
  let token = localStorage.getItem('idToken');
  if (token) {
    let decoded = jwtDecode(token);
    let expiryTime = new Date(decoded.exp * 1000);
    let currentTime = new Date();
    let MS_PER_MINUTE = 60000;
    const expiryEpochMinus = expiryTime.getTime() - 5 * MS_PER_MINUTE;
    if (expiryEpochMinus <= currentTime.getTime()) {
      await refreshToken();
      return localStorage.getItem('idToken');
    } else {
      return token;
    }
  } else {
    return null;
  }
};

axios.interceptors.response.use(null, (error) => {
  if (!error.response.config.isNormal) {
    if (error && error.response) {
      if (error.response.status === 401) {
        // initial call to whoami will get intercepted here and redirect, need to throw log event
        // TODO: Do something
        handleSignOut();
        localStorage.clear();
        window.location.replace('/');
      }
      if (error.response.status === 400) {
        // initial call to whoami will get intercepted here and redirect, need to throw log event
        // TODO: Do something
        // Auth.signOut();
        // localStorage.clear();
        // window.location.replace('/');
      }
      const { data } = error.response;
      const errorPayload =
        data.error ||
        data.errors ||
        data.error_message ||
        data.error_type ||
        data.message;

      return Promise.reject(errorPayload);
    }

    return Promise.reject(error);
  } else {
    return Promise.reject(error.response.data);
  }
});

axios.interceptors.request.use(
  (config) =>
    new Promise((resolve) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        'idToken'
      )}`;
      resolve(config);
      // Auth.currentSession()
      //   .then((session) => {
      //     const idTokenExpire = session.getIdToken().getExpiration();
      //     const refreshToken = session.getRefreshToken();
      //     const currentTimeSeconds = Math.round(+new Date() / 1000);
      //     if (idTokenExpire < currentTimeSeconds) {
      //       Auth.currentAuthenticatedUser().then((res) => {
      //         res.refreshSession(refreshToken, (err, data) => {
      //           if (err) {
      //             Auth.signOut();
      //             localStorage.clear();
      //             window.location.replace('/');
      //           } else {
      //             config.headers.Authorization = `Bearer ${data
      //               .getIdToken()
      //               .getJwtToken()}`;
      //             localStorage.setItem(
      //               'idToken',
      //               data.getIdToken().getJwtToken()
      //             );
      //             resolve(config);
      //           }
      //         });
      //       });
      //     } else {
      //       config.headers.Authorization = `Bearer ${session
      //         .getIdToken()
      //         .getJwtToken()}`;
      //       resolve(config);
      //     }
      //   })
      //   .catch(() => {
      //     // No logged-in user: don't set auth header
      //     resolve(config);
      //   });
    })
);
/**
 *
 * @param {*} path  endpoint
 * @returns data from api
 */
export async function apiGet(path) {
  const authToken = await getToken();
  const config = {
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : null,
    },
  };

  return axios.get(path, config);
}
export async function apiGetNoAuth(path) {
  const authToken = encrypt(generateRandomId());
  const config = {
    headers: {
      Authorization: authToken,
    },
  };

  return axios.get(path, config);
}
/**
 *
 * @param {*} path   endpoint
 * @param {*} data object of data
 * @returns   data from api
 */
export async function apiPost(path, data, isNormal = false) {
  const authToken = await getToken();
  const config = {
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : null,
      'Content-Type': 'application/json',
    },
    isNormal: isNormal,
  };

  return axios.post(path, data, config);
}
/**
 *
 * @param {*} path   endpoint
 * @param {*} data object of data
 * @returns   data from api
 */
export async function apiPatch(path, data, headers = {}) {
  const authToken = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      ...headers,
    },
  };

  return axios.patch(path, data, config);
}
/**
 *
 * @param {*} path   endpoint
 * @param {*} data object of data
 * @returns   data from api
 */
export async function apiPut(path, data, isNormal = false) {
  const authToken = await getToken();
  const config = {
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : null,
      'Content-Type': 'application/json',
    },
    isNormal: isNormal,
  };

  return axios.put(path, data, config);
}
export async function apiPutNoAuth(path, data, isNormal = false) {
  const authToken = encrypt(generateRandomId());

  const config = {
    headers: {
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
    isNormal: isNormal,
  };

  return axios.put(path, data, config);
}
/**
 *
 * @param {*} path   endpoint
 * @param {*} data params
 * @returns   data from api
 */
export async function apiDelete(path, data) {
  const authToken = await getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  return axios.delete(path, { ...config, ...data });
}

/**
 *
 * @param {*} path   endpoint
 * @param {*} data object of data
 * @returns   data from api
 */
export function apiPostWithCustomToken(path, data, token) {
  const config = {
    headers: {
      Authorization: token ? `Basic ${token}` : null,
      'Content-Type': 'application/json',
    },
  };

  return axios.post(path, data, config);
}

const instance = axios.create();

export default instance;
